import React from "react";
import { StoreContext } from "context/StoreContext";
import { formatPrice } from "utils/formatPrice";

import { Fragment, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  MenuIcon,
  SearchIcon,
  ShoppingBagIcon,
  XIcon as XIconOutline,
} from "@heroicons/react/outline";
import {
  CheckIcon,
  ClockIcon,
  MinusIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  XIcon as XIconSolid,
} from "@heroicons/react/solid";
import { hasNativeLazyLoadSupport } from "gatsby-plugin-image/dist/src/components/hooks";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const LineItem = ({ item }) => {
  const { featureImages, removeLineItem, checkout, updateLineItem } =
    React.useContext(StoreContext);
  return (
    <>
      <div className="flex-shrink-0">
        <div className="w-24 h-24 rounded-md object-center object-cover sm:w-48 sm:h-48">
          {featureImages[item.variant.sku]}
        </div>
      </div>
      <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
        <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
          <div>
            <div className="flex justify-between">
              <h3 className="text-sm">{item.title}</h3>
            </div>
            <p className="mt-1 text-sm font-medium text-gray-900">
              {formatPrice(item.variant.priceV2.currencyCode, Number(item.variant.priceV2.amount))}
            </p>
          </div>

          <div className="mt-4 sm:mt-0 sm:pr-9">
            <div className="flex items-center space-x-3">
              {item.quantity > 1 ? (
                <button
                  type="button"
                  onClick={() =>
                    updateLineItem(checkout.id, item.id, Math.max(1, item.quantity - 1))
                  }
                  className="bg-gray-200 rounded-full p-1"
                >
                  <MinusIcon className="h-3 w-3" />
                </button>
              ) : (
                <div className="bg-white rounded-full p-1">
                  <MinusIcon className="h-3 w-3 text-white" />
                </div>
              )}

              <div>{item.quantity}</div>

              <button
                type="button"
                onClick={() => updateLineItem(checkout.id, item.id, item.quantity + 1)}
                className="bg-gray-200 rounded-full p-1"
              >
                <PlusIcon className="h-3 w-3" />
              </button>
            </div>

            <div className="absolute top-0 right-0">
              <button
                type="button"
                className="-m-2 p-2 inline-flex text-gray-400 hover:text-gray-500"
                onClick={() => removeLineItem(checkout.id, item.id)}
              >
                <span className="sr-only">Remove</span>
                <XIconSolid className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function Example() {
  const { checkout } = React.useContext(StoreContext);

  return (
    <div className="bg-white">
      <main className="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Tu carrito
        </h1>

        <form className="mt-12 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
          <section aria-labelledby="cart-heading" className="lg:col-span-7">
            <h2 id="cart-heading" className="sr-only">
              Items in your shopping cart
            </h2>

            <ul role="list" className="border-t border-b border-gray-200 divide-y divide-gray-200">
              {checkout?.lineItems?.map((item) => (
                <li key={item.id} className="flex py-6 sm:py-10">
                  <LineItem item={item} key={item.id} />
                </li>
              ))}
            </ul>
          </section>

          {/* Order summary */}
          <section
            aria-labelledby="summary-heading"
            className="mt-16 bg-gray-50 rounded-lg px-4 py-6 sm:p-6 lg:p-8 lg:mt-0 lg:col-span-5"
          >
            <h2 id="summary-heading" className="text-lg font-medium text-gray-900">
              Tu pedido
            </h2>

            <dl className="mt-6 space-y-4">
              <div className="flex items-center justify-between">
                <dt className="text-sm text-gray-600">Subtotal</dt>
                <dd className="text-sm font-medium text-gray-900">
                  {checkout.subtotalPriceV2 &&
                    formatPrice(
                      checkout.subtotalPriceV2.currencyCode,
                      checkout.subtotalPriceV2?.amount
                    )}
                </dd>
              </div>
              <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                <dt className="flex items-center text-sm text-gray-600">
                  <span>Gastos de envió estimados</span>
                  {/* <a href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Learn more about how shipping is calculated</span>
                    <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                  </a> */}
                </dt>
                <dd className="text-sm font-medium text-gray-900">FREE</dd>
              </div>
              <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                <dt className="text-base font-medium text-gray-900">Total</dt>
                <dd className="text-base font-medium text-gray-900">
                  {checkout.totalPriceV2 &&
                    formatPrice(checkout.totalPriceV2.currencyCode, checkout.totalPriceV2?.amount)}
                </dd>
              </div>
            </dl>

            <div className="mt-6">
              <a href={checkout.webUrl}>
                <button
                  type="button"
                  className="w-full bg-[#1d4aff] border border-transparent hover:shadow-sm py-3 px-4 text-base font-medium text-white hover:brightness-[1.75] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500"
                  children="Realizar Pedido"
                />
              </a>
            </div>
          </section>
        </form>
      </main>
    </div>
  );
}
